import type { DataItemModel } from 'o365-dataobject';
import type { EventArgType } from 'o365-modules';
import type { UploadOptions } from './Types.ts';

import { API } from 'o365-modules';
import { $t } from 'o365-utils';
import { default as FileUpload, ProgressHandler } from './FileUpload.ts';
import { DataObject } from 'o365-dataobject';
import { EventEmitter } from 'o365-modules';
import { zeroBytesFilesAlert, dataURIToFile, blobToFile, alert } from './FileUploadUtils.ts';


declare module "o365-dataobject" {
    interface DataObject {
        _fileUpload: DataObjectFileUpload;
        fileUpload: DataObjectFileUpload
    }
}

Object.defineProperty(DataObject.prototype, "fileUpload", {
    get: function fileUpload() {
        if (!this._fileUpload) this._fileUpload = new DataObjectFileUpload(this);

        return this._fileUpload;
    }
});


class DataObjectFileUpload {
    private _dataObject: DataObject;
    private _files: Array<File> | undefined | null;
    private _fileUpload: FileUpload;
    private _records: Array<any>;
    private _whiteList: any;
    private _chunkInitUrl: string | null = null;
    eventHandler: EventEmitter<{}, FileUploadEvents>;
    beforeUpload: Function | undefined;
    onCompleted: Function | undefined;
    progress: ProgressHandler;
    skipRecordCreate: boolean = false;

    testUpload: boolean = true;
    private _haventTriedToGetWhiteList: boolean;
    uploading: boolean = false;

    set files(pList) {
        if (pList && pList.constructor === FileList) {
            this._files = Array.from(pList);
        } else {
            this._files = pList;
        }
    }

    set maxConcurrentUploads(pUploads: number) {
        this.fileUpload.maxConcurrentUploads = pUploads;
    }


    get files() {
        return this._files;
    }

    set useChunks(pValue: string) {
        this._fileUpload.useChunks = !!pValue;
    }
    set url(pValue: string) {
        this._fileUpload.url = pValue;
    }

    get fileUpload() {
        return this._fileUpload;
    }

    onAsync<K extends keyof FileUploadEvents>(event: K, listener: FileUploadEvents[K]) {
        return this.eventHandler.onAsync(event, listener);
    }

    async emitAsync<K extends keyof FileUploadEvents>(event: K, ...args: EventArgType<FileUploadEvents, K>) {
        return this.eventHandler.emitAsync(event, ...args);
    }

    constructor(pDataObject: DataObject) {
        this._dataObject = pDataObject;
        this._fileUpload = new FileUpload({
            url: `/api/file/upload/${this._dataObject.uniqueTable ?? this._dataObject.viewName}`,
            useChunks: true,
            viewName: this._dataObject.viewName
        });
        this._haventTriedToGetWhiteList = true;
        this.eventHandler = new EventEmitter();
        this.progress = new ProgressHandler();
        this._records = [];
    }
    async getWhiteList() {
        this._haventTriedToGetWhiteList = false;
        try {
            this._whiteList = await API.requestGet('/api/file/get-extension-whitelist')

        } catch (ex) {
            console.warn(ex)
        }

    }
    public getDataObject() {
        return this._dataObject;
    }

    async upload(pOptions: UploadOptions, pData: any, allowedFormats?: string | string[]) {
        let allowedFormatsToParse: string[] = [];
        if (allowedFormats) {
            if (Array.isArray(allowedFormats)) {
                allowedFormats.forEach(str => {
                    if (typeof str == 'string') {
                        allowedFormatsToParse.push(str.toUpperCase())
                    }
                })
            }
            if (typeof allowedFormats == 'string') {
                allowedFormatsToParse.push(allowedFormats.toUpperCase())
            }
        }
        if (this._haventTriedToGetWhiteList) {
            await this.getWhiteList();
        }
        if (!pOptions.files) {
            return;
        }

        if (zeroBytesFilesAlert(pOptions.files)) {
            return;
        }
        if (this.files && this.files.indexOf(pOptions.files[0]) > -1) return;
        if (typeof pOptions.files[0] === 'string') {
            pOptions.files[0] = dataURIToFile(pOptions.files[0])!;
        }

        if (pOptions.files[0] instanceof Blob && !(pOptions.files[0] instanceof File)) {
            pOptions.files = blobToFile(pOptions.files as Array<Blob>);
        }

        if (pOptions.files) {
            this.files = pOptions.files as File[];
        }
        if (pOptions['chunkInitUrl']) this._chunkInitUrl = pOptions['chunkInitUrl'];
        const vUploads = [];

        let vBeforeUploadParams: any;

        if (!this._files) return Promise.resolve();
        if (!this._files.length) return Promise.resolve();
        if (this._files.length === 0) return Promise.resolve();

        let vData: any = {};
        if (this._dataObject.masterDetails.isSet) {
            vData = Object.assign(vData, this._dataObject.masterDetails.getMasterDetailRowForInsert());
        }
        if (this.beforeUpload) vBeforeUploadParams = this.beforeUpload.call(this, ...arguments);
        if (vBeforeUploadParams === false) return Promise.resolve();
        if (vBeforeUploadParams && typeof vBeforeUploadParams == "object") {
            Object.keys(vBeforeUploadParams).forEach(key => {
                vData[key] = vBeforeUploadParams[key];
            })
        }

        vBeforeUploadParams = null;
        if (pOptions && pOptions.beforeUpload) vBeforeUploadParams = pOptions.beforeUpload.call(this, ...arguments);
        if (pOptions && vBeforeUploadParams === false) return Promise.resolve();
        if (pOptions && vBeforeUploadParams && typeof vBeforeUploadParams == "object") {
            Object.keys(vBeforeUploadParams).forEach(key => {
                vData[key] = vBeforeUploadParams[key];
            })
        }

        if (pOptions.files) {
            this.files = pOptions.files as File[];
        }
        let cancelRequest = false;

        if (this._whiteList && this._whiteList.length > 0) {
            if (this.files) {
                this.files.forEach(file => {
                    let split = file.name.split('.')
                    if (split.length > 1) {
                        if (!this._whiteList.includes(split[split.length - 1].toUpperCase())) {
                            cancelRequest = true;
                        }
                    } else {
                        cancelRequest = true;
                    }
                })
                if (cancelRequest) {
                    alert($t('Is not an allowed file extension. Allowed extentions are : '+this._whiteList), "danger")

                    return Promise.reject(new Error("Is not an allowed file extension.'"));
                }
            }
        }
        if (allowedFormatsToParse && allowedFormatsToParse.length > 0) {
            if (this.files) {
                this.files.forEach(file => {
                    let split = file.name.split('.')
                    if (split.length > 1) {
                        if (!allowedFormatsToParse.includes(split[split.length - 1].toUpperCase())) {
                            cancelRequest = true;
                        }
                    } else {
                        cancelRequest = true;
                    }
                })
                if (cancelRequest) {

                    alert($t('Is not an allowed file extension. Allowed extentions are : ') + allowedFormatsToParse, "danger")

                    return Promise.reject(new Error("Is not an allowed file extension.'"));
                }
            }
        }

        vBeforeUploadParams = {};
        try {
            await this.emitAsync('BeforeUploadAsync',vBeforeUploadParams );
        } catch (ex) {
            if (pOptions.onError) pOptions.onError.call(this, ...arguments);
            alert($t(ex.message), "danger")
            return Promise.reject(ex);
        }
        this.uploading = true;
        this.progress.start(pOptions);
        this.progress.message = `${$t('Starting to upload')} ${this.files?.length} ${$t('files')}`;
        if (vBeforeUploadParams === false) return Promise.resolve();
        if (vBeforeUploadParams && typeof vBeforeUploadParams == "object") {
            Object.keys(vBeforeUploadParams).forEach(key => {
                vData[key] = vBeforeUploadParams[key];
            });
        }
        if (pData) {
            vData = Object.assign(pData, vData);
        }
        this._records = [];
        for (let i = 0; i < this._files.length; i++) {
            let vFile = this._files[i];
            let fileData = null;
            if (Array.isArray(vData)) {
                fileData = vData ? [...vData] : [];

            } else if (typeof vData === 'object' && vData !== null) {
                fileData = vData ? { ...vData } : {};

            }
            try {
                await this.emitAsync('BeforeFileUploadAsync', vFile, fileData);
            } catch (ex) {
                if (pOptions.onError) pOptions.onError.call(this, ...arguments);
                this.progress.message = `${$t('Error')}`;
                this.uploading = false;
                return Promise.reject(ex);
            }
            vUploads.push(this.uploadFile2(vFile, fileData))

        }

        return Promise.all(vUploads).then(async (data: any) => {

            if (this.testUpload && !this.skipRecordCreate) {


                data = await this._createOrUpdateRecordInStorage(data);


            }
            if (this.skipRecordCreate) {
                if (pOptions.onCompleted) pOptions.onCompleted.call(this, data, ...arguments);
                if (this.onCompleted) this.onCompleted.call(this, ...arguments);
            }
            if (!data || !data[data.length - 1]) return;
            this.uploading = false;

            if (!this.skipRecordCreate) {
                if (pOptions.onCompleted) pOptions.onCompleted.call(this, data, ...arguments);
                if (this.onCompleted) this.onCompleted.call(this, ...arguments);
            }
            this.progress.message = `${$t('Upload complete')}`;
            this._records = [];
            if(!this.skipRecordCreate){
                if (this._dataObject.dynamicLoading.enabled) {
                    this._dataObject.dynamicLoading.dataLoaded(this._dataObject.storage.data, { skip: 0 });
                }
                if (data.length === 1)
                    if (data[0].dataObjectId) {
                        this._dataObject.setCurrentIndexByPrimKey(data[0].primKey);
                    } else {
                        if (data[data.length - 1]?.PrimKey) {
                            this._dataObject.setCurrentIndexByPrimKey(data[data.length - 1].PrimKey);
                        } else {
                            this._dataObject.setCurrentIndexByPrimKey(data[data.length - 1][0][0]['PrimKey']);
                        }

                    }
            }
            this.progress.completeUpload();
            return data;
        }).catch((e) => {
            //wht to do ?
            console.error(e);
            if (pOptions.onError) pOptions.onError.call(this, ...arguments);
            this.progress.message = `${$t('Error')}`;
            this.uploading = false;
            alert(e);
        });


    }

    disableNTHeader() {
        this._fileUpload.disableNTHeader();
    }

    cancel() {
        this.uploading = false;
        this.files = null;
        this.progress.message = `${$t('Canceling upload')}`;
        this._records.forEach(rec => {
            if (rec.primKey) {
                this._dataObject.cancelChanges(rec.item.index);
            } else {
                this._dataObject.deleteItem(rec.item);
            }
        });
        this._records = [];
        this._fileUpload.abort();
    }

    async createOrUpdateRecord(pFile: File, pData: any) {
        if (pData.hasOwnProperty("PrimKey")) {
            this.progress.message = `${$t('Updating record')}: ${pFile.name} `;
        } else {
            this.progress.message = `${$t('Creating record')}: ${pFile.name}`;
        }

        const vData = Object.assign(pData, {
            FileName: pFile.name,
            FileSize: pFile.size,

        });
        let vRow: DataItemModel | undefined = undefined;
        if (pData.hasOwnProperty("PrimKey")) {
            if (this._dataObject.batchDataEnabled) {
                vRow = this._dataObject.storage.getItemByPrimKey(pData["PrimKey"]) ?? this._dataObject.batchData.storage.getItemByPrimKey(pData["PrimKey"])
            } else {
                vRow = this._dataObject.storage.getItemByPrimKey(pData["PrimKey"])
            }
            if (vRow == null) {
                throw new TypeError(`Cannot find item with ${pData['PrimKey']} PrimKey in storage for update`);
            }
            //will be update operatrion
            if (vRow.index < 0) {
                this._dataObject.batchData.storage.updateItem(this._dataObject.batchData.getInversedIndex(vRow.index), vData, false);
            } else {
                this._dataObject.storage.updateItem(vRow.index, vData, false);
            }
            this._records.push({
                item: vRow,
                primKey: pData["PrimKey"]
            });
            //   return Promise.resolve(vRow);
        } else {
            //create            
            const options = {
                ...this._dataObject.recordSource.getOptions(),
                uniqueTable: this._dataObject.uniqueTable,
                values: pData,
            }
            return this._dataObject.dataHandler.create(options).then((pRow) => {
                if (pData.index != null) {
                    // get real row and update it
                    vRow = pData.index < 0
                        ? this._dataObject.batchData.storage.updateItem(this._dataObject.batchData.getInversedIndex(pData.index), pRow[0], true)
                        : this._dataObject.storage.updateItem(pData.index, pRow[0], true);
                } else {
                    vRow = this._dataObject.createNew(pRow[0], false);
                    vRow.reset();
                    vRow.state.isNewRecord = false;
                    this._records.push({
                        item: vRow,
                        primKey: null
                    });
                }
                return vRow;
            });
            // return this._dataObject.recordSource.save(vRow.index,null).then(_=>{

        }
        return vRow.save();
    }

    async _createOrUpdateRecordInStorage(pData: Array<any>) {

        const vUpdateRecords = pData.filter(x => x.PrimKey);
        const vInsertRecords = pData.filter(x => !x.PrimKey);
        let vRow: DataItemModel | undefined = undefined;
        if (vUpdateRecords.length) {
            vUpdateRecords.forEach(x => {
                vRow = this._dataObject.storage.getItemByPrimKey(x["PrimKey"]);
                if (!vRow) {
                    vInsertRecords.push(x);
                    return;
                }
                if (vRow.index < 0) {
                    this._dataObject.batchData.storage.updateItem(this._dataObject.batchData.getInversedIndex(vRow.index), x.ResponseData, true);
                } else {
                    this._dataObject.storage.updateItem(vRow.index, x.ResponseData, true);
                }
                this._records.push({
                    item: x.ResponseData,
                    primKey: x["PrimKey"]
                });
            })
        }

        if (vInsertRecords.length) {
            if(vInsertRecords.find(x=>x.ResponseData)){
                this._dataObject.storage.setItems(vInsertRecords.map(x => x.ResponseData));
                if (this._dataObject.state.rowCount !== null)
                    this._dataObject.state.rowCount += vInsertRecords.length;
            }




        }

        if (this._dataObject.hasDynamicLoading) {
            this._dataObject.dynamicLoading.dataLoaded(this._dataObject.data, { skip: 0 });
        }




        return pData.map(x => x.ResponseData);
    }

    async _uploadFile(pFile: File, pData: any) {
        const options: any = {
            ...this._dataObject.recordSource.getOptions(),
            uniqueTable: this._dataObject.uniqueTable,
            values: pData,

        }

        const vRes = await this._fileUpload.uploadChunks({
            chunkInitUrl: this._chunkInitUrl,
            file: pFile,
            data: options,
            onProgress: (e: ProgressEvent) => this.progress.updateProgress(pFile, e)
        });
        if (pData.PrimKey) vRes["PrimKey"] = pData.PrimKey;
        return vRes;

    }

    async uploadFile2(pFile: File, pData: any) {
        if (this.testUpload) {
            //  pData = Object.assign(pData,{ FileName: pFile.name,
            //   FileSize: pFile.size
            //  }
            //)
            return this._uploadFile(pFile, pData);
        }
        try {
            const vRef = await this._uploadFile2(pFile, pData);
            pData["FileRef"] = vRef['FileRef'];
            this.progress.message = `${$t('Uploading file: ')} ${pFile.name}`;

            if (this.skipRecordCreate) return { ...pData, ...vRef };
            return this.createOrUpdateRecord(pFile, pData).then((row) => {
                return row;
            }).catch(ex => {
                //record update failed
                console.error(ex);
                this.progress.message = `${$t('Error')}`;
                // this.progress.completeUpload();
                this.cancel();
                this.progress.error = ex;
                return Promise.reject(ex);
            });
        } catch (ex: any) {
            this.progress.updateError(pFile, ex?.message ?? ex ?? $t('Upload failed'));
        }


        /*return this.createOrUpdateRecord(pFile,pData).then(row=>{
            this.progress.message = `${$t('Uploading file: ')} ${pFile.name}`;
            return this._uploadFile(pFile,pData,row);
        })*/
    }

    private async _uploadFile2(pFile: File, pData: any) {
        return await this._fileUpload.upload({
            file: pFile,
            data: pData,
            //   uploadRef: pPrimKey,
            onProgress: (e: ProgressEvent) => this.progress.updateProgress(pFile, e)
        })
    }


}

export type FileUploadEvents = {
    "BeforeUploadAsync": (pBeforeUploadParams?: object) => Promise<object>,
    "BeforeFileUploadAsync": (pFile: File, fileData: object) => Promise<object>,
}

export { DataObjectFileUpload };

